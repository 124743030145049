<template>
<div style="margin:auto; width: 30%">

<!--el-row style="padding-top:0px;">
  <div style="margin: auto; width:70%; font-size: 18px;">
  <img  style="width:350px; margin-top: -20px" alt="logo" src="../assets/logo2.png">
  </div>
</el-row-->

<el-row style="padding-top:40px;">
  <div style="margin: auto; width:80px; color: orange; font-size: 20px;"> 用户登录</div>
</el-row>

<el-row style="padding-top:0px;">
  <div style="margin: auto; width:70%; font-size: 18px;">

  <el-row style="text-align: center;padding-top:20px">
    <el-input style="" placeholder="机构名称(即企事业单位名称)" type="text" v-model="groupName" @blur="chkGroup"> </el-input>
  </el-row>
  
  <el-row style="text-align: center;padding-top:20px">
    <el-input style="" placeholder="手机号" type="text" v-model="loginAcct"> </el-input>
  </el-row>
  
  <el-row style="text-align: center;padding-top:20px">
    <el-input placeholder="密码" type="password" v-model="password" style=""> </el-input>
  </el-row>

  </div> 
</el-row>

<el-row style="padding-top:30px;">
  <div style="margin: auto; width: 280px; "> <el-button style="width:280px" type="primary" size="large" @click="login" >登录</el-button > </div>
</el-row>

<el-row style="padding-top:20px;">
  <div style="margin: auto; width: 280px; "> <el-button style="width:280px" type="primary" size="large" @click="cancel" >取消</el-button > </div>
</el-row>

<el-row style="padding-top:30px;">
  <div style="margin: auto; width: 280px; "> <el-button style="width:280px" type="primary" size="large" @click="toRegister" >注册</el-button > </div>
</el-row>

</div>
</template>
<script>
import RL from '../reqs/RL.js'
import JiGou from '../reqs/jiGou.js'

export default {
name: 'Login',

components: {
},

data() {
return {
  rl: {}, //注册登录接口
  jg: {}, //机构接口

  groupId: "",
  groupName: "",
  loginAcct: "",
  password: "",

};
},

mounted() {
  //console.log("in mounted", this.sLogonState);

  //this.groupId = 1000001;
  //this.loginAcct = "1861001";

  this.rl = new RL();
  this.jg = new JiGou();
},

methods:{
toHome() {
  this.$router.push({path: '/', query:{x: ""}});
},

cancel() {
  this.toHome();
},

chkGroup() {
  //console.log("chkGroup");

  if (this.groupName == "") {
    this.$message("机构名称不能为空");
  }

  //查询机构信息，获取机构id
  this.jg.getByName(this.groupName)
  .then((res) => {
    if (res.code != "200000") {
      if (res.code == "EJF4") {
        this.$message("获取机构信息失败: 服务执行中错误:EJF4");
      } else if (res.code == "EJF12") {
        this.$message("获取机构信息失败: 没有该机构");
      } else {
        this.$message("获取机构信息失败: 未知错误");
      }

      return;
    }
   
    this.groupId = res.data.id;
  })
  .catch(err => {
    this.$message("不能获取机构信息");
    console.log("exception:", err.toString());
  });
},

login() {
  //console.log("login");

  if (this.groupName == "") {
    this.$message("机构名称不能为空");
		return;
  }

  if (this.groupId == "") {
    this.$message("无效的机构名称, 请输入注册时使用的机构名称");
		return;
  }

  if (this.loginAcct == "") {
    this.$message("手机号不能为空");
		return;
  }

  if (this.password == "") {
    this.$message("密码不能为空");
		return;
  }

  this.rl.login(this.groupId, this.loginAcct, this.password)
  .then((res) => {
    if (res.code != "200000") {
      if (res.code == "EJF4") {
        this.$message("登录失败: 服务执行中错误:EJF4");
      } else if (res.code == "EJF12") {
        this.$message("登录失败: 登录号不存在:" + this.loginAcct);
      } else if (res.code == "EJF13") {
        this.$message("登录失败: 登录号["+ this.loginAcct +"]不属于该机构["+ this.groupName +"]");
      } else if (res.code == "EJF14") {
        this.$message("登录失败: 密码错误");
      } else if (res.code == "EJF15") {
        this.$message("登录失败: 未能获取令牌");
      } else {
        this.$message("登录失败: 服务执行中错误");
      }

      return;
    }

    let tkn = res.data;
    let strings = tkn.split(".");
    let s1 = decodeURIComponent(escape(window.atob(strings[1])));
    console.log("1", s1);

    var userinfo = JSON.parse(s1);

    if (userinfo.st != "1") {
      this.$message("登录失败: 该用户已被禁止登录");
      return;
    }

    this.$store.dispatch("fSetLogonState", true);

    localStorage.setItem('gId', this.groupId);
    localStorage.setItem('gn',  this.groupName);
    localStorage.setItem('la',  this.loginAcct);     //当前登录号
    localStorage.setItem('tkn', tkn);
    localStorage.setItem('uc', userinfo.uc);    //系统编码
    localStorage.setItem('id', userinfo.id);     //session id
    localStorage.setItem('st', userinfo.st);

		//转到积分网站
    //this.$router.push({path: '/', query:{x: ""}});
    let wurl = window.configs.svcJifenPcWebProt + '://' + window.configs.svcJifenPcWebIpPort +"/?tkn=" + tkn;
		window.location.href = wurl + "&gId=" + this.groupId + "&gn=" + this.groupName + "&la=" + this.loginAcct + "&uc=" + userinfo.uc;
  })
  .catch(err => {
    this.$message("登录失败: 网络或者服务故障");
    console.log("exception:", err.toString());
  });
},

toRegister() {
  this.$router.push({path: '/register', query:{x: ""}});
},

}

}
</script>

